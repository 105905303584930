import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import configDetail from '@/view/publicDNS/config/config.vue';
import policyDetail from '@/view/publicDNS/config/policy.vue';
import statDetail from '@/view/publicDNS/config/stat.vue';
export default {
  components: {
    configDetail: configDetail,
    policyDetail: policyDetail,
    statDetail: statDetail
  },
  name: "itemDetail",
  data: function data() {
    return {
      activeName: 'tab-config',
      confName: this.$route.query.name,
      bindIP: ''
    };
  },
  methods: {
    goBack: function goBack() {
      this.$router.push({
        name: "pubdns"
      });
    },
    handleChangeTab: function handleChangeTab() {
      if (this.activeName == 'tab-stat') {
        this.$refs.statDetail.getQueries();
      }
    },
    changeConf: function changeConf(name, bindIP) {
      this.confName = name;
      this.bindIP = bindIP;
    }
  }
};