var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-page-header", {
    attrs: {
      content: _vm.confName
    },
    on: {
      back: _vm.goBack
    }
  }), _c("el-tabs", {
    on: {
      "tab-click": _vm.handleChangeTab
    },
    model: {
      value: _vm.activeName,
      callback: function callback($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: _vm.$t("public-dns.item.config"),
      name: "tab-config"
    }
  }, [_c("configDetail", {
    on: {
      changeConf: _vm.changeConf
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: _vm.$t("public-dns.item.policy"),
      name: "tab-policy"
    }
  }, [_c("div", {
    staticClass: "tip-warn"
  }, [!_vm.bindIP ? _c("el-alert", {
    attrs: {
      title: _vm.$t("public-dns.rule.bindip-warn"),
      type: "warning",
      "show-icon": ""
    }
  }) : _vm._e()], 1), _c("policyDetail")], 1), _c("el-tab-pane", {
    attrs: {
      label: _vm.$t("public-dns.item.stat"),
      name: "tab-stat"
    }
  }, [_c("statDetail", {
    ref: "statDetail"
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };