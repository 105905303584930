import { render, staticRenderFns } from "./itemDetail.vue?vue&type=template&id=0ce065d4&scoped=true&"
import script from "./itemDetail.vue?vue&type=script&lang=js&"
export * from "./itemDetail.vue?vue&type=script&lang=js&"
import style0 from "./itemDetail.vue?vue&type=style&index=0&id=0ce065d4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ce065d4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/lucky.zhang/workspaces/workspace_go/src/routerman/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0ce065d4')) {
      api.createRecord('0ce065d4', component.options)
    } else {
      api.reload('0ce065d4', component.options)
    }
    module.hot.accept("./itemDetail.vue?vue&type=template&id=0ce065d4&scoped=true&", function () {
      api.rerender('0ce065d4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/view/publicDNS/itemDetail.vue"
export default component.exports